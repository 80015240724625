import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "rafflelandingPage",
    component: () => import("@/components/customRaffles/rafflelandingPage.vue"),
  },
  {
    path: "/customRafflePage/:charityRef/:raffleCode",
    name: "RafflePage",
    component: () => import("@/components/customRaffles/customRafflePage.vue"),
  },
  {
    path: "/payment/success",
    name: "successPage",
    component: () =>
      import("@/components/customRaffles/rafflesConfirmationPage.vue"),
  },
  {
    path: "/payment/failed",
    name: "customRaffleFailurePage",
    component: () =>
      import("@/components/customRaffles/customRaffleFailurePage.vue"),
  },
  {
    path: "/pageNotFound",
    name: "pageNotFound",
    component: () => import("@/components/customRaffles/pageNotFoundPage.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "notFound",
    component: () => import("@/components/customRaffles/pageNotFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
